import { TaxCategoryState } from "@/vuex/modules/taxCategory/taxCategory.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./taxCategory.actions";
import { getters } from "./taxCategory.getters";
import { mutations } from "./taxCategory.mutations";
import { state } from "./taxCategory.state";

const namespaced: boolean = true;
export const TaxCategoryModule: Module<TaxCategoryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
