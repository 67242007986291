import { TaxCategory } from "@/interfaces/taxCategory";
import { TaxCategoryState } from "@/vuex/modules/taxCategory/taxCategory.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type TaxCategoryGetters = GetterTree<TaxCategoryState, RootState>;

export const getters: TaxCategoryGetters = {
  taxCategories(state: TaxCategoryState): TaxCategory[] {
    return state.taxCategories;
  },
  taxCategory(state: TaxCategoryState): TaxCategory | null {
    return state.taxCategory;
  },
  loading(state: TaxCategoryState): boolean {
    return state.loading;
  },
  pagination(state: TaxCategoryState): TablePagination | null {
    return state.pagination;
  }
};
