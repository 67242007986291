import { TaxCategoryModule } from "@/vuex/modules/taxCategory/taxCategory.index";
import { Component, Vue } from "vue-property-decorator";

/**
 * component to manage the product categories
 */
@Component({
  render: c => c("router-view")
})
export default class TaxCategoriesComponent extends Vue {
  protected created() {
    this.$store.registerModule("TaxCategoryModule", TaxCategoryModule);
  }

  protected beforeDestroy() {
    this.$store.unregisterModule("TaxCategoryModule");
  }
}
