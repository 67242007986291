import { TaxCategory } from "@/interfaces/taxCategory";
import { TaxCategoryState } from "@/vuex/modules/taxCategory/taxCategory.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type TaxCategoryMutationTree = MutationTree<TaxCategoryState>;

export const mutations: TaxCategoryMutationTree = {
  setLoading(state: TaxCategoryState, loading: boolean) {
    state.loading = loading;
  },
  setTaxCategories(state: TaxCategoryState, taxCategories: TaxCategory[]) {
    state.taxCategories = taxCategories;
  },
  setTaxCategory(state: TaxCategoryState, taxCategory: TaxCategory) {
    state.taxCategory = taxCategory;
  },
  setPagination(state: TaxCategoryState, pagination: TablePagination | null) {
    state.pagination = pagination;
  }
};
