import { TaxCategory } from "@/interfaces/taxCategory";
import { store } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { taxCategoryService } from "@/services/taxCategory.service";
import { TaxCategoryState } from "@/vuex/modules/taxCategory/taxCategory.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type TaxCategoryActionContext = ActionContext<TaxCategoryState, RootState>;
type TaxCategoryActionTree = ActionTree<TaxCategoryState, RootState>;

export const actions: TaxCategoryActionTree = {
  async loadTaxCategories(context: TaxCategoryActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const taxCategories: TaxCategory[] = await taxCategoryService.get();
      const pagination: TablePagination = await taxCategoryService.getPagination();
      context.commit("setPagination", pagination);
      context.commit("setTaxCategories", taxCategories);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async findTaxCategory(
    context: TaxCategoryActionContext,
    dataRequest: {
      id: number;
      route?: string;
    }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const taxCategory = await taxCategoryService.findById(
        Number(dataRequest.id)
      );
      context.commit("setTaxCategory", taxCategory);
    } catch (error) {
      store.dispatch("RouterModule/errorRedirect", {
        location: { name: "tax-category-list" },
        error
      });
    } finally {
      context.commit("setLoading", false);
    }
  },
  async saveTaxCategory(
    context: TaxCategoryActionContext,
    taxCategory: TaxCategory
  ): Promise<any> {
    if (taxCategory.id) {
      // @ts-ignore
      taxCategory._method = "PUT";
      await taxCategoryService.put(taxCategory, taxCategory);
    } else {
      await taxCategoryService.post(taxCategory);
    }
    messagesService.renderSuccessMessage("tax_category_saved");
  },
  async deleteTaxCategory(
    context: TaxCategoryActionContext,
    taxCategory: TaxCategory
  ): Promise<any> {
    try {
      await taxCategoryService.deleteForce(taxCategory);
      messagesService.renderSuccessMessage("success_delete_tax_category");
      context.dispatch("loadTaxCategories");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
};
